.footer {
	background: $dark-burgundy;
	padding: 5em 0;

	.container {
		display: flex;
		flex-direction: column;

		.logo-container {
			height: 100px;
			display: flex;
			align-items: center;
			margin-bottom: 1em;

			a {
				/*max-height: 100%;*/
				height: 100%;

				.logo {
					max-height: 100%;
				}
			}

		}
		.tfk p, .ghai p {
			font-size: 1.75em;
			@media #{$tablet-landscape-up} {
				font-size: 2em;
			}
		}
		.ghai {
			margin-top: 2em;
			/*padding-left: 5em;*/

			.logo {
				height: 80px;
			}
		}
		.social-icons {
			justify-content: space-between;
			flex-wrap: wrap;

			.follow {
				display: flex;
				align-items: center;
				width: 100%;
			}
			@media (min-width: 350px) {
				.learn, .follow {
					width: auto;
				}

			}
		}
		@media #{$tablet-up} {
			flex-direction: row;

			.tfk {
				padding-right: 3em;
			}
			.ghai {
				padding-left: 3em;
				margin-top: 0;
			}
		}
		@media #{$laptop-up} {
			.tfk {
				padding-right: 5em;
			}
			.ghai {
				padding-left: 5em;
			}
		}
	}
	hr {
		margin: 4em 0 2em;
	}
	.contact-container p {
		text-align: right;
		width: 100%;

		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}