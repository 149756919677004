.support {
	background: $burgundy;
	padding: 5em 0;
	position: relative;
	background: url('/assets/images/support-bg-no-img.png') bottom center / contain no-repeat $burgundy;

	.support-images {
		position: absolute;
		z-index: 2;
		top: 0;
		width: 100%;
		display: none;
		/*max-width: 1400px;
		left: 50%;
		transform: translateY(-50%);*/

		img {
			position: absolute;
			max-width: 200px;

			&.kids {
				top: 50px;
				left: 3%;

				@media #{$desktop-up} {
					right: calc(50% + 350px);
    				left: auto;
				}
			}
			&.juul {
				top: -12px;
				right: 3%;

				@media #{$laptop-up} {
					top: -15px;
				}

				@media #{$desktop-up} {
					top: -20px;
					left: calc(50% + 350px);
    				right: auto;
				}
			}

			@media #{$laptop-up} {
				max-width: 250px;
			}
			@media #{$desktop-up} {
				max-width: 300px;
			}
		}
		@media #{$tablet-landscape-up} {
			display: block;
		}
	}
	.container {
		padding: 5em 0 0;
	}
	.content {
		text-align: center;
		max-width: 550px;
		margin: 0 auto;
		z-index: 10;
		position: relative;

		@media #{$laptop-up} {
			max-width: 600px;
		}
	}

	@media #{$laptop-up} {
		padding: 10em 0;
	}
	@media #{$desktop-up} {
		background-size: cover;
	}
}