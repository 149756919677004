.sponsors {
	background: $brown;
	padding: 5em 0;

	h5 {
		font-size: 2em;
		color: $white;
		text-align: left;
		border-bottom: 1px solid $white;
		padding-bottom: 2px;
		margin-top: 0;
	}

	img {
		height: auto;

		@media #{$up-to-phablet} {
			margin-bottom: 20px;
		}
	}

	.sponsor-level {
		padding: 1.75em 1em;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&.pseudo-after{
			justify-content: space-between;

			&:after {
			    content: "";
			    flex: auto;
			}
		}

		&.allies {
			@media #{$laptop-up} {
				justify-content: space-between;
			}
		}
	
		&> img {
			padding-right: 0px;

			@media #{$phablet-up} {
				padding-right: 30px;
			}

			@media #{$tablet-up} {
				padding-right: 50px;
			}

			@media #{$tablet-landscape-up} {
				padding-right: 80px;
			}
		}

		&.benefactors div {
			width: 100%;
			max-width: 500px;
			margin-bottom: 3em;
		}
		&.advocates img{
			width: 100%;
			max-width: 350px;
			margin-bottom: 3em;

			&.gsk {
				width: auto;
				max-height: 180px;

				@media #{$phablet-up} {
					max-height: 130px;
				}
			}
		}
		&.patrons img {
			width: 100%;
			margin-bottom: 3em;

			@media #{$up-to-phablet} {
				max-width: 80%;
			}
		}
		&.allies img {
			width: 100%;
			margin-bottom: 3em;

			&.nvg {
				margin-top: 20px;

				@media #{$laptop-up} {
					margin-top: 0;
				}
			}
			&.kaiser, &.jhu {
				@media #{$laptop-up} {
					padding-right: 40px !important;
				}
			}
			@media #{$up-to-phablet} {
				max-width: 80%;
			}
		}
		&.allies div {
			width: 100%;
			font-size: 2.5em;
			padding: 0 .5em 2em 0;
		}
		&.premiers div, &.supporters div {
			width: 100%;
			color: $white;
			font-size: 2.5em;
			padding: 0 .5em .5em;

			@media #{$tablet-landscape-up} {
				font-size: 2.75em;
			}
		}
		&.supporters div {
			font-size: 2em;
			padding-bottom: .5em;

			@media (min-width: 700px) {
				padding-bottom: .75em;
			}
			@media #{$tablet-landscape-up} {
				font-size: 2.25em;
			}
		}
		@media #{$phablet-up} {
			&.benefactors div {
				width: 45%;

				& + div {
					margin-left: 5%;
				}
			}
			&.advocates img {
				width: 40%;
			}
			&.patrons img {
				width: 33.33%;
			}

			&.patrons img.bpi {
				width: 40%;
			}
			&.patrons img.riester {
				max-width: 30%;
			}
			&.allies img {
				max-width: 32%;
				width: auto;
			} 
			&.allies img.jhu {
				min-width: 375px;
			}
			&.allies img.plowshare {
				min-width: 300px;
			}
			&.allies div {
				width: 290px;
				font-size: 2.15em;
				text-align: center;
			}
			&.premiers div, &.supporters div {
				width: 100%;
			}
		}

		@media #{$tablet-up} {
			&.advocates img {
				width: 45%;
			}
			&.patrons img {
				width: 28%;
			}
			&.allies img {
				width: auto;
				max-width: 32%;
			}
			&.allies div {
				max-width: 33%;
				width: auto;
			}
			&.premiers div, &.supporters div {
				width: 33%;
				padding-right: 15px;
			}
		}
		@media #{$laptop-up} {
			&.allies img {
				max-width: 21%;
			}

			&.allies img.jhu, &.allies img.plowshare {
				max-width: 33%;
			}

			&.allies div {
				max-width: 22%;
			}
		}
		@media #{$laptop-up} {
			&.advocates img {
				padding-right: 40px;
	    		max-width: 27% !important;
			}
		}
	}
	.button-holder {
		text-align: center;
		margin-top: 2rem;
	}
	.jhu {
		max-width: 100%;
	}
	.max-height {
		max-height: 300px !important;
		width: auto !important;

		&.mlb {
			max-height: 200px !important;
		}
	}
}