p {
	font-size: 1.6em;
    line-height: 1.6em;
    margin: 0 0 1.5rem;
    color: $white;
    @media #{$phablet-up} {
		font-size: 1.8em;
	}
	@media #{$tablet-up} {
		font-size: 2em;
	}
}

a {
	cursor: pointer;
	color: $white;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

div {
	color: $white;
}

dfn,
cite,
em,
i {
	font-style: italic;
}