.share {
	background: $dark-burgundy;
	padding: 5em 0;

	.container {
		display: flex;
		align-items: center;
		flex-direction: column;

		.column-left {
			padding: 3em 0;

			h1 {
				text-align: center;

				@media #{$tablet-up} {
					text-align: left;
				}
			}
			.social-icons {
				justify-content: center;

				img {
					width: 30px;
				}
				@media #{$tablet-up} {
					justify-content: flex-start;
				}
			}
			@media #{$tablet-up} {
				padding: 0;
			}
		}
		.column-right {
			/*width: 70%;*/
			transform: translateX(150%);
			margin-bottom: 3em;
			transition: all 1s;

			&.visible {
				transform: none;
			}

			img {
				min-width: 400px;
				max-width: 100%;
			}
			@media #{$tablet-up} {
				margin-bottom: 0;
				width: 70%
			}
		}
		@media #{$tablet-up} {
			flex-direction: row;
		}
	}
}

.at-share-btn-elements {
	.at-share-btn {
		background-color: transparent !important;
		width: 35px;
		padding: 0 !important;
		margin: 0 !important;
	}
}

.join-us .container {
	.share-label {
		font-size: 19px;
		display: none;

		@media #{$tablet-up} {
			display: block;
		}
	}

	.at-share-btn-elements {
		.at-share-btn {
			border-bottom: none !important;
			margin-bottom: 0 !important;
		}
	}
}

.share2 {
	display: none;
}