.program {
	background: $burgundy;
	padding: 5em 0 0;
	overflow-y: hidden;

	.container {
		h1 {
			margin-top: 1em;

			@media #{$up-to-phablet} {
				br {
					display: none;
				}
			}
		}
		.subhead {
			font-size: 2.25em;
			margin: 1em auto;
			max-width: 900px;
			line-height: 1.25;
			text-align: center;

			@media #{$phablet-up} {
				font-size: 2.75em;
			}

			@media #{$tablet-up} {
				font-size: 3.25em;
			}
		}
		.program-button {
			text-align: center;
		}
	}
	.images {
		position: relative;
		margin-top: 4em;
		overflow: hidden;

		@media #{$phablet-up} {
			margin-top: 8em;
		}

		.background {
			width: 125%;
			max-width: 125%;
			left: -12.5%;
			z-index: 5;
			position: relative;

			&.three-teens {
				width: 100%;
				position: absolute;
				left: 0;
				display: block;

				@media #{$up-to-tablet-landscape} {
					width: 100%;
					position: absolute;
					left: 0;
					display: none;
				}
			}
			&.with-text {
				display: none;
				position: relative;

				@media #{$tablet-landscape-up} {
					display: block;
					transform: translateY(100%);
					transition: all 1s;

					&.visible {
						transform: none;
					}
				}
			}
		}
		.image-mobile {
			display: block;
			margin: 0 auto 5em;

			@media #{$tablet-landscape-up} {
				display: none;
			}
		}
	}

}