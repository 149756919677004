@import "container";
@import "footer";
@import "header";

main[role="main"] {
	overflow: hidden;
	transition: all .5s;

	&.opaque {
		opacity: .3;
	}
}

body {
	overflow-x: hidden;

	&.frozen {
		overflow-y: hidden;
	}
}