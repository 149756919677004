/** Set Base Font Sizes */

body {
	font-size: 62.5%;
	font-weight: 300;
}

body,
button,
input,
select,
optgroup,
textarea {
	font-family: $font-sans-serif;
}

@import 'headings';
@import 'copy';
