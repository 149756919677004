.container {
	width: 90%;
	max-width: 1220px;
	margin: 0 auto;
	&-alt {
		width: 90%;
		max-width: 1014px;
		margin: 0 auto;
	}
}
