a,
:link,
:visited {
	text-decoration: none;
}

a {
	transition: .25s all ease-in-out;

	&:hover {
		/*color: $red;*/
		/*text-decoration: none;*/
	}
}

p a,
p :link,
p :visited  {	
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}

.section-title {
	text-align: center;
	display: flex;
    align-items: center;
    flex-direction: column;
    width: 150px;
    margin: 0 auto;
/*	&:before {
		display: inline-block;
		content: "";
		background: url('/assets/images/h4.png') center/contain no-repeat;
		width: 100px;
		height: 40px;
	}*/
	/*&:not('');*/

	.svg-container {
		overflow: hidden;
		max-height: 45px;

		svg {
		  height: 75px;
		  /*position: relative;*/
		  /*overflow: visible;		    */

			path, rect, circle, #circle-5 {
			   transition: all 1s;

			}
			circle, #circle-5 {
				opacity: 0;
			}
		}
	}

	&.mobile {
		@media #{$laptop-up} {
			display: none;
		}
	}

	&.desktop {
		display: none;

		@media #{$laptop-up} {
			margin-top: -2rem;
		    margin-bottom: 3.5rem;
		    padding-left: 2.5rem;
		    display: flex;
		}

		@media #{$desktop-up} {
			/*margin-top: -4.5rem;*/
		    margin-bottom: 5.5rem;
		    padding-left: 2.5rem;
		}

		h4 {
			text-align: left;
		}
	}
}

.social-icons {
	font-family: $font-condensed;
	font-size: 1rem;
	display: flex;
	align-items: center;

	span {
		display: inline-block;
		margin-right: 15px;
	}

	.follow a {
		transition: all .2s ease-in-out;

		img {
			width: 20px;
			margin-right: 10px;
			padding-top: 5px;

			&.linkedin {
				width: 17px;
			}
		}

		&:hover {
			transform: translateY(-4px);
		}
	}
	@media #{$tablet-up} {
		justify-content: flex-start;
	}
}

.more {
	font-family: $font-condensed;
	font-size: 1rem;

	a:after {
		content: "";
		color: red;
		margin-left: 5px;
		display: inline-block;
		height: 10px;
		width: 10px;
		background: url('/assets/images/carat-icon.png') center / contain no-repeat;
	}
}

.at-share-btn-elements {
	position: relative;
	right: -5px;
}

@import 'buttons';
@import 'hamburger';