.hamburger {
	background-color: #380214;
	cursor: pointer;
	display: block;
	height: 40px;
	margin-left: calc(100% - 45px);
	width: 45px;
	margin-top: -25px;
    margin-bottom: 20px;

	@media #{$tablet-up} {
		display: none;
	}

	span {
		background-color: $white;
		display: block;
		height: 3px;
		margin-top: 7px;
		margin-left: 15%;
		width: 70%;
	}
}