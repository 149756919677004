.site-header {
	background-color: $dark-burgundy;
	position: fixed;
	width: 100%;
	z-index: 50;
	border-bottom: 1px solid $white;
	transition: all .5s;

	@media #{$up-to-tablet} {
		position: fixed;
	}

	&.opaque {
		opacity: .3;
	}

	.container {
		display: flex;
		justify-content: space-between;
		padding: 25px 0;
		align-items: center;
		flex-direction: column;

		.logo {
			max-width: 100%;
			text-align: center;

			a {
				display: inline-block;

				&.event-logo {
					max-width: 70%;
					width: 250px;
					padding-right: 20px;
					border-right: 1px solid $white;

					@media #{$phablet-up} {
						max-width: 300px;
						width: 300px;
						padding-right: 30px;
					}

					@media #{$tablet-up} {
						width: 235px;
					}
					@media #{$tablet-landscape-up} {
						width: 300px;
					}
				}
				&.tfk-logo {
					max-width: 18%;
					width: 65px;
					margin-left: 20px;
					/*width: 55px;*/

					@media (min-width: 450px) {
						width: 65px;
					}

					@media #{$phablet-up} {
						margin-left: 30px;
						width: 65px;
						max-width: 65px;
					}

					@media #{$tablet-up} {
						width: 55px;
					}
					@media #{$tablet-landscape-up} {
						width: 65px;
					}
				}
			}
		}
		.menu {
			width: 40%;
			display: flex;
			justify-content: space-between;
			min-width: 450px;
			margin-top: 1.25rem;
			flex-wrap: wrap;
			max-width: 100%;

			@media #{$up-to-tablet} {
				display: none;
				position: absolute;
				background-color: #380214;
				top: 125px;
				width: 100%;
				left: 0;
				padding: 0 0 2rem;
				border-bottom: 1px solid $white;

				&.open {
					display: block;
				}
			}

			.menu-item {
				font-family: $font-condensed;
				font-weight: 400;
				width: 50%;
				text-align: center;
				line-height: 1.75;
				font-size: 1.25rem;

				@media #{$up-to-tablet} {
				    width: 100%;
    				border-bottom: 1px solid $white;
    			}

    			&:last-of-type {
    				@media #{$up-to-tablet} {
					    width: 100%;
	    				border-bottom: none;
	    			}
    			}


				a.btn {
					display: inline;
					font-size: 1.5rem;
					padding-bottom: .75rem;

					@media #{$up-to-tablet} {
						display: table;
					    margin-left: 5vw;
					    /*padding: .25rem 1.5rem .25rem;*/
					    padding: .25rem 1.5rem .5rem;
					    margin-top: .75rem;
					    margin-bottom: -1rem;
					}
				}

				a:not(.btn) {
					padding: 5px 0;

					@media #{$up-to-tablet} {
						display: block;
						padding: 1rem 5vw;
						text-align: left;
						font-size: 24px;
					}

					&:hover {
						color: $white;
					}
				}
				@media #{$phablet-up} {
					text-align: left;
					width: auto;
					line-height: 1;
				}
				@media #{$tablet-landscape-up} {
					font-size: 1.5rem;
				}
			}
			@media #{$tablet-up} {
				margin-top: 0;
			}
		}
		@media #{$tablet-up} {			
			flex-direction: row;
		}
	}
}

.phase3 .site-header {
	.container .menu {
		@media #{$tablet-up} {
			width: 30%;
			min-width: 350px;
		}
	}
}