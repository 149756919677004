.honorees {
	background: $brown;
	padding: 7em 0 5em;

	h1 {
		padding-bottom: .5em;
	}

	h5 {
		font-size: 2rem;
		color: $white;
		text-align: left;
		border-bottom: 1px solid $white;
		padding-bottom: 8px;
		font-weight: 700;

		@media #{$laptop-up} {		
			font-size: 2.5rem;
		}
	}

	.winners, .selected-winner {
		position: relative;

		.close-winner {	
			display: none;
			cursor: pointer;
			float: right;
			top: -2rem;
			position: relative;
			right: -1.25rem;
		}
		.winner {
			margin: 3rem 0 4rem;

			&.alt-winner {
				margin-top: 0rem;

				.winner-flex-container {
					margin-top: 1rem;
				}
				.info {
					.basic {
						@media #{$tablet-up} {
							top: 75px;
						}
						@media #{$tablet-landscape-up} {
							top: 0;
						}
					}
					@media #{$tablet-landscape-up} {
						margin-top: 2.5rem;
					}

				}
			}

			&.has-border {
				padding-bottom: 0;
				border-bottom: 1px solid $burgundy;
				margin-bottom: 6rem;
			}
			.winner-flex-container {
				display: flex;
				margin: 2rem 1rem 2rem 0;

				img.headshot {
					/*margin-right: 6rem;*/
					/*margin-right: 4rem;
					margin-left: 4rem;*/
					width: 300px;
					height: 310px;

					&.alt-headshot {
						margin: 0; 
						width: 320px;
						height: 336px;

						@media #{$tablet-up} {
							margin: 0 1.5rem 0 0;
							width: 390px;
							height: 410px; 
						}

						@media #{$laptop-up} {
							margin: 0 1rem;
						    width: 460px;
						    height: 483px;
						}
					}

					@media #{$laptop-up} {
						margin-right: 4rem;
						margin-left: 4rem;
					}

					@media #{$tablet-up} {
						width: 363px;
						height: 380px;
					}
					&.alt-size {
						height: 225px;

						@media #{$tablet-up} {
							height: 272px;
						}
					}
				}
				.basic {
					font-size: 1.1rem;
					
					strong {
						font-size: 1.75rem;

						@media #{$tablet-up} {
							font-size: 2rem;
						}
					}

					p {
						font-size: 1.4rem;
						margin-top: .25rem;
					}
					.small {
						font-size: 1.1rem;
						margin: .25rem 0 1.5rem;
						line-height: 1.5;

						strong {
							font-size: 1.1rem;
							font-weight: 600;
						}

						div {
							padding-bottom: .25em;
						}	
					}					
				}
				.more {
					font-size: 1.25rem;

					a:after {
						margin-bottom: 1px;
						height: 12px;
						width: 12px;
						margin-left: 8px;
					}
				}
				.more-info {
					display: none;
				}
			}
		}
		&.advocates {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.winner {
				width: 100%;
				margin: 0 !important;

				@media #{$laptop-up} {
					margin: 0 80px !important;
				}
				@media #{$desktop-up} {
					margin: 0 100px !important;
				}

				.winner-flex-container {
					margin-right: 0;
					flex-direction: column;
					justify-content: left;

					img.headshot {
						height: auto;
						margin-left: 0;
						margin-right: 0;

						@media (min-width: 700px) {
							margin: 0 0 1rem 0;
						}
					}
					.basic {
						margin-top: 1rem;
						width: 100%;
						max-width: 100%;
						
						@media (min-width: 700px) {
							margin-top: 0;
						}
					}					
				}
				@media (min-width: 700px) {
					width: 40%;
					margin: 0 5% 1rem 0;
				}
				@media #{$tablet-up} {
					width: 363px;
				    margin: 0 0 1rem 0;
				}
			}
		}
	}
	.winners.single, .selected-winner {
		.winner-flex-container {
			flex-direction: column;
			width: 100%;

			img.headshot {
				margin-bottom: 25px;

				@media #{$phablet-up} {
					margin-bottom: 25px;
					height: 310px;
					width: 300px;
				}
				@media #{$tablet-up} {				
					height: 380px;
					width: 363px;
				}
				@media #{$tablet-landscape-up} {
					margin-bottom: 0;
					margin-right: 3rem;
					margin-left: 0;
				}
				@media #{$laptop-up} {
					margin-right: 4rem;
					margin-left: 4rem;
				}
			}
			.basic {
				margin-top: 1.5rem;
				margin-top: 0;

				@media #{$phablet-up} {
					width: calc(100% - 325px);
					position: absolute;
					left: 325px;
					top: 50px;
					margin-top: 0;
				}
				@media #{$tablet-up} {
					width: calc(100% - 400px);
				    left: 400px;
				}
				@media #{$tablet-landscape-up} {
					width: 100%;
					position: static;
				}
			}
			@media #{$tablet-landscape-up} {
				flex-direction: row;
			}
		}
	}
	.winners.single {
		.bio {
			position: relative;
			.close-winner {
				position: absolute;
			}
		}
		.info {
			@media (min-width: 1360px) {
				float: right;
				max-width: calc(100% - 411px);
			}
		}
	}
	.selected-winner {
		.close-winner {
			right: 0;
			top: .75rem;
		}
		.winner.nya .info .basic {
			@media #{$up-to-tablet} {
				top: 0;
			} 
		}
	}
}