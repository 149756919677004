h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-sans-serif;
	margin: 0 0 1rem;
	color: $white;
	line-height: 1.1em;
	font-weight: 400;
	text-align: center;
}

h1 {
	/*font-size: 2.6em;*/
	font-size: 3.25em;
	line-height: 1.1em;
	font-weight: 800;
	
	@media #{$phablet-up} {
		font-size: 3.8em;
	}
	@media #{$tablet-up} {
		font-size: 4.75em;
	}
	@media #{$laptop-up} {
		font-size: 5.5em;
	}
}


h2 {
	font-size: 2.25em;
	/*font-size: 2.75em;*/
	/*line-height: 1.1em;*/
	
	@media #{$phablet-up} {
		font-size: 2.75em;
	}
	@media #{$laptop-up} {
		font-size: 3.75em;
	}

}

h3 {
	/*font-size: 1.2em;*/
	font-size: 1.75em;
	/*line-height: 1.1em;*/
	
	@media #{$phablet-up} {
		font-size: 2em;
	}
	@media #{$tablet-up} {
		font-size: 2.25em;
	}
	@media #{$laptop-up} {
		font-size: 2.75em;
	}

}

h4 {
	text-transform: uppercase;
	letter-spacing: 4px;
	/*font-size: 1.2em;*/
	font-size: 1.5em;
	font-weight: 600;
	margin-top: .5em;

	/*line-height: 1.1em;*/
	/*
	@media #{$phablet-up} {
		font-size: 1.5em;
	}*/
	@media #{$tablet-up} {
		font-size: 2em;
	}
}