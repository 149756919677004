.this-year {
	background-color: $brown;
	padding: 4em 0;

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column-reverse;

		p {
			margin: 0;
			text-align: center;

			@media #{$tablet-up} {
				margin: 0 4em 0 0;
				text-align: left;
			}
		}
		img {
			width: 300px;
			max-width: 300px;
			min-width: 300px;
			margin-bottom: 3em;

			@media #{$tablet-up} {
				margin-bottom: 0;
			}
		}
		@media #{$tablet-up} {
			flex-direction: row;
		}	
	}
}