.watch-live {
	width: 100vw;
	background: url('/assets/images/bg-lt-red.png') center/cover no-repeat;
	position: relative;
	margin-top: 149px;
	min-height: calc(100vh - 149px);

	.container {
		z-index: 5;
		max-width: 800px;
		padding: 2rem 0;
	    text-align: center;
	    position: relative;

	    .section-title {
	    	h4 {
	    		font-size: 1rem;
	    		margin-top: 1em;
	    	}

	    	.svg-container svg #circle-5,  .svg-container svg circle {
	    		opacity: 1;
	    	}
	    }
	    h2 {
	    	font-weight: 700;
	    	margin: 3rem 0;
	    }
	    p {
	    	font-size: 1rem;
	    	margin-top: 1rem;
	    }
	   	.more {
	    	margin-top: 4rem;
	    	font-size: 1.25rem;

	    	a:after {
	    		height: 12px;
	    		width: 12px;
	    	}

	    	@media (min-width: 750px) {
	    		margin-top: 2rem;
	    	}
	    }
	    .social {
	    	position: absolute;
	    	background: $red;
	    	border-radius: 0 0 25px 25px;
	    	right: 0;
	    	display: flex;
		    flex-direction: row;
		    justify-content: space-around;
		   	height: 40px;
		   	width: 140px;
		   	padding: 0 10px;
		   	align-items: center;

		   	.addthis_inline_share_toolbox {
		   		height: 100%;
		   		width: 100%;

		   		#atstbx {
		   			height: 100%;

		   			.at-share-btn-elements {
		   				height: 100%;
		   			    display: flex;
					    flex-direction: column;
					    justify-content: space-between;	
					    align-items: center;
					    left: 0;

					    .at-icon-wrapper {
					    	width: 40px !important;
					    }	   			
		   			}
		   		}

		   	}

	    	a {
	    		display: block;

	    		img {
		    		width: 25px;
		    		height: auto;

		    		@media #{$phablet-up} {
		    			width: 30px;
		    		}
		    	}
	    	}
	    	@media #{$phablet-up} {
	    		height: 50px;
		   		width: 160px;
	    	}
	    	@media (min-width: 750px) {
	    		right: 0;
	    		left: auto;
	    		width: 60px;
	    		height: 150px;
	    		/*width: 50px;
	    		height: 150px;*/
	    		top: 50%;
	    		transform: translate(100%, -50%);
	    		padding: 15px 0 20px;
	    		flex-direction: column;
	    		border-radius: 0 25px 25px 0;
	    	}
	    	@media #{$tablet-up} {
	    		width: 80px;
	    		padding: 30px 0;
	    		height: 200px;
	    	}
	    }
	    @media (min-width: 750px) {
	    	padding: 2rem 80px;
	    }
	    @media (min-height: 950px) {
	    	position: absolute;
	    	top: 50%;
	    	left: 50%;
	    	transform: translate(-50%, -50%);
	    }
	    @media #{$tablet-landscape-up} {
	    	padding: 2rem 0 4rem;
	    }
	}

	.wave-image {
		display: none;

		@media #{$tablet-landscape-up} {
			display: block;
			position: absolute;
			bottom: 0;
			z-index: 4;
			max-width: 55%;
			left: -13%;
		}

		@media #{$tablet-landscape-up} {
			left: -10%;
			max-width: 45%;
		}
		@media #{$laptop-up} {
			left: 0;
			max-width: 35%;
		}
		@media #{$desktop-up} {
			max-width: 30%;
		}
	}

	@media #{$tablet-up} {
		margin-top: 108px;
		min-height: calc(100vh - 108px);
	}

	@media #{$laptop-up} {
		min-height: calc(100vh - 120px);
		margin-top: 120px;
	}

	@media (min-height: 1400px) {
		min-height: 0;
		height: 1400px;
	}
}