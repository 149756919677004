.btn {
	color: $white;
	background: $red;
	text-decoration: none;
	padding: .5rem 1.5rem .75rem;
	border-radius: 25px;
	font-size: 1.25rem;
	display: inline-block;
	/*border: 2px solid $red;*/
	transition: .25s all ease-in-out;
	font-family: $font-condensed;

	&:hover {
		color: $white;
	}
}

.program-button {	
	a {
		text-decoration: none;
		font-size: 1.5rem;

		&:hover {
			text-decoration: underline;
		}
	}
}