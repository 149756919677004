.join-us {
	background: bottom right / 500px no-repeat $burgundy;
	padding: 5em 0;

	@media #{$up-to-tablet} {
		margin-top: 135px;
	}

	.container {
		@media #{$laptop-up} {
			margin: 0 5% 0 30px;
			max-width: calc(100% - 30px);
		}

		.columns {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			h1, h2, h3 {
				text-align: left;
			}

			h1 {
				@media #{$desktop-up} {
					font-size: 4.0vw;
				}
			}

			h2 {
				margin-bottom: .25rem;
				
				@media #{$desktop-up} {
					font-size: 2.85vw;
				}
			}
			h3 {
				margin-bottom: 2rem;
				line-height: 1.25;

				span {
					padding: 0 5px;

					@media #{$phablet-up} {
						padding: 0 10px;
					}
				}
				@media #{$tablet-up} {
					margin: 1.75rem 0;
				}
			}

			.column-left {
				width: 90vw;
				max-width: 100%;
				padding-right: 0;

				@media #{$tablet-up} {
					width: 40vw;
					margin-top: 2rem;
				}

				@media #{$laptop-up} {
					margin-top: 4rem;
				}

				@media #{$desktop-up} {
					margin-top: 5rem;
				}

				.countdown-images {
					position: relative;
					height: 72vw;

					@media #{$phablet-up} {
						height: 65vw;
					}

					@media #{$tablet-up} {
					    height: 33vw;
					}

					img, .circle {
						position: absolute;
					}
					.fade-in-photo {
						opacity: 0;
						transition: all 2s;

						&.visible {
							opacity: 1;
						}
						/*&.popUpVideo {
							cursor: pointer;
						}*/
					}
					.teen {
						z-index: 1;
						left: 0;
						width: 30%;
						top: 28%;
						display: none;

						@media #{$tablet-up} {
							display: block;
							left: .5vw;
						}
					}
					.award {
						z-index: 2;
						width: 45%;
						left: 15%;
						bottom: 0;
						display: none;

						@media #{$tablet-up} {
							display: block;
						}
					}
					.allyssa {
						z-index: 5;
						width: 70vw;
						right: 0;
						cursor: default;

						&.live {
							cursor: pointer;
						}

						@media #{$phablet-up} {
							width: 63vw;
						}

						@media #{$tablet-up} {
							width: 23vw;
							left: 35%;
							right: auto;
						}
					}
					.circle {
						z-index: 10;
						height: 30vw;
						width: 30vw;
						border-radius: 50%;
						background-color: $red;
						padding-top: 10px;
						left: 2vw;
						top: 10%;

						@media #{$phablet-up} {
							height: 25vw;
    						width: 25vw;
    						left: 6vw;
    						top: 7%;
						}

						@media #{$tablet-up} {
							left: 18%;
						    height: 10vw;
    						width: 10vw;
    					}

						.countdown {
							position: absolute;
							left: 50%;
							transform: translate(-50%, -50%);
							top: 50%;
							font-size: 1.75em;
							text-align: center;
							line-height: .9;

							@media #{$tablet-up} {
								font-size: 1.8em;
							}

							@media #{$desktop-up} {
								font-size: 24px;
							}

							.number {
								font-weight: bold;
								/*font-size: 3.5em;*/
								line-height: 1;
    							font-weight: 800;
    							display: inline-block;
    							margin-top: -5px;
    							font-size: 2.25rem;
    							margin-bottom: .25rem;

    							@media #{$phablet-up} {
    								font-size: 2.75rem;
    								margin-bottom: .5rem;
    							}

								@media #{$tablet-up} {
									font-size: 1.7rem;
									margin-bottom: .25rem;
								}

								@media #{$laptop-up} {
									font-size: 2.25rem;
								}

								@media #{$desktop-up} {
									font-size: 2.75rem;
								}

								&.few-digits {
									font-size: 3.5em;

									@media #{$tablet-up} {
										font-size: 2.25em;
									}

									@media #{$desktop-up} {
										font-size: 80px;
									}
								}
							}
							.interval {
								line-height: 1.1;
								font-size: 1.25em;

								@media #{$tablet-up} {
									font-size: 1em;
								}
							}
						}
					}
				}
				@media (min-width: 725px) {
					padding-right: 5em;
				}
			}
			.column-right {
				margin-top: 1em;
				width: 100%;

				.program-button a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				.partners {
					margin-top: 3rem;

					.partner-logos {
						display: block;
						align-items: center;

						.partner-logo {
							display: block;
							margin: 0 .5em .75em 0;
							max-width: 250px;

							& + .partner-logo {
								margin: 2em 0 0 0;

								@media #{$phablet-up} {
									margin: 0 0 0 3em;
									padding-bottom: .75em;
								}
							}
							@media #{$phablet-up} {
								display: inline-block;	
							}
						}
						@media #{$phablet-up} {
							display: flex;
						}
					}
				}
				@media #{$laptop-up} {
					width: 50vw;
				}
				@media #{$desktop-up} {
				    width: 41vw;
    				margin-right: 2vw;
    			}
			}
			@media #{$laptop-up} {
				flex-direction: row;
			}
			.device-headings {
				display: block;
				max-width: calc(100% - 500px);
				margin-top: 2em;

				h1, h2, h3 {
					text-align: center;

					@media #{$tablet-up} {
						text-align: left;
					}
				}

				@media #{$tablet-up} {
					margin-top: 3em;
				}
	
				@media #{$laptop-up} {
					display: none;
					margin-top: 6em;
				}

			}
			.column-right .laptop-headings {
				display: none;

				@media #{$laptop-up} {
					display: block;
				}
			}
			.links {
				font-size: 3.25em;
				font-weight: 800;
				position: relative;
				top: -2em;
				display: none;

				.link {
					text-align: right;
					margin-bottom: 0;

					&.share-link {
						 margin-bottom: 3rem;
					}

					.share-label {
						font-size: 1rem;
					    font-weight: 500;
					    text-transform: uppercase;
					    font-family: $font-condensed;
					    padding-bottom: 3px;
					}
					&.logo-link {
						margin: 0;
						position: absolute;
						right: -10px;
					}
				}
				a {
					display: inline-block;
					width: auto;
					margin-bottom: 2rem;
					position: relative;
					border-bottom: 4px solid $white;

					&:hover {
						text-decoration: none;
					}
					&.tfk-logo {
						width: 100px;
						border-bottom: none;
						margin-bottom: 0;
					}
					@media #{$tablet-up} {
						border-bottom-width: 6px;
					}

				}
				@media #{$laptop-up} {
					display: block;
					top: -4em;
				}
				@media #{$desktop-up} {
					top: -5em;
				}
			}
			.device-links {
				display: flex;
				top: 0;
				font-size: 2em;
				width: 100%;
				justify-content: space-around;
				margin: 2em 0 0;
    			flex-wrap: wrap;

    			.link {
    				padding: 0 .5rem .5rem;
    			}

				@media (min-width: 725px) {
					font-size: 2.5em;
					width: auto;
					display: block;
					margin-top: 0;
				}

				@media #{$tablet-up} {
					display: none;
					top: -1em;
					font-size: 3.25em;
				}

			}
			@media #{$up-to-tablet} {
				.device-headings {
					order: 1;
					width: 100%;
					max-width: 100%;
				}
				.column-left {
					order: 2;
				}
				.device-links {
					order: 3;
				}
				.column-right {
					order: 4;
				}
			}
		}

		.section-title {
			margin-left: 0;
			margin-right: 0;
		}

		@media #{$up-to-laptop} {
			.column-left {
				order: 1;
			}
			.device-headings {
				order: 2;
			}
			.links.device-links {
				top: 0;
				order: 3;
				width: 100%;
    			display: flex;
    			margin: 2rem 0 0;

    			a {
    				margin: 0;
    			}
			}
			.column-right {
				order: 4;
				margin-top: 1rem;
			}
		}
		@media #{$laptop-up} {
			margin: 0 5% 0 30px;
			max-width: calc(100% - 30px);
		}
	}

	@media #{$tablet-up} {
		margin-top: 108px;
		padding-bottom: 12em;
		background-image: url('/assets/images/joinus-bg-flip.png');
		background-position-y: 175%;
		transition: all 1s;

		&.visible {
			background-position-y: bottom;
		}
	}

	@media #{$laptop-up} {
		background-image: url('/assets/images/joinus-bg.png');
		background-position-x: left;
		margin-top: 120px;
	}
}

.calendar-options {
	position: fixed;
	top: 145px;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,.5);
	z-index: 10;
	display: none;
	overflow: scroll;

	@media #{$landscape} {	
    	height: 50vw;
	}

	@media #{$tablet-up} {
		top: 120px;
    	height: 100vh;
	}

	&.visible {
		display: block;
	}

	&__content {
		padding: 4rem 3rem;
		color: $white;
		background-color: $burgundy;
		width: 90%;
		max-width: 650px;
		margin: 150px auto;
		border-radius: 25px;
	}

	.buttons {
		margin: 2rem 0;

		@media #{$tablet-up} {
			display: flex;
			justify-content: space-between;
			max-width: 500px;
    		margin: 2rem auto 1rem;
		}

		@media #{$tablet-up} {

		}
	}

	i {
		font-size: 60px;
		display: block;
		text-align: center;
		margin-bottom: .5rem;
		margin-bottom: 1.5rem;
	}

	.btn {	
		text-align: center;
		min-width: 240px;

		@media #{$up-to-tablet} {
			display: table;
			margin: 0 auto 1rem;
		}
	}

	.close {	
		cursor: pointer;
		float: right;
		position: absolute;
		display: inline-block;
		top: 5em;
    	right: 6vw;

		@media #{$tablet-up} {
			top: 3em;
			right: 6em;
		}
		@media #{$laptop-up} {
			top: 3em;
		}
		img {
			height: 30px;
		}
	}
}